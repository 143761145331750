:root {
  --navy: #303040;
  --red: #e53725;
}

body {
  /* background-image: url("./assets/images/bknd.jpg"); */
  background-image: url("./assets/images/transparent-bknd.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  color: var(--navy);
}

@font-face {
  font-family: 'ElCamino';
  src: url('./assets/fonts/ElCaminoRegular.ttf');
}

@font-face {
  font-family: 'NexaRust';
  src: url('./assets/fonts/Fontfabric\ -\ Nexa\ Rust\ Sans\ Black.otf'); /* letter-spacing: 3px; */
}

h1 {
  font-family: ElCamino;
  font-weight: bolder;
  color: var(--red);
}

h2, h3, h4, h5, h6 {
  font-family: NexaRust;
  color: var(--navy);
}

h2 {
  border-bottom: solid var(--red) 2px;
  margin: 15px;
  padding-bottom: 15px;
}

h3 {
  font-family: ElCamino;
  /* color: var(--red); */
}

footer.container-fluid h4 {
  color: white;
}

header {
  border-bottom: solid var(--navy) 7px;
}

#logo-text {
  font-size: 0.75em;
  word-wrap: normal;
}

.header-text span {
  font-family: NexaRust;
  color: var(--navy);
}

.header-text span a {
  color: var(--red);
}

.navbar-light .navbar-nav .nav-link {
  font-family: NexaRust;
  letter-spacing: 3px;
  color: var(--navy);
  transition: 0.2s;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: var(--red);
}

.logo {
  max-width: 200px;
}

footer {
  border-top: solid var(--red) 7px;
}

footer.container-fluid {
  background-color: var(--navy);
}

footer.container-fluid p {
  font-family: NexaRust;
  color: white;
}

footer.container-fluid p a {
  color: var(--red);
}

footer.container-fluid h3 {
  color: white;
}

footer.container-fluid img {
  width: 200px;
}

svg {
  color: var(--red);
  color: white;
  margin: 10px;
  transition: 0.2s;
  /* width: 3em; */
  /* padding: 10px; */
}

footer svg:hover {
  color: white;
  color: var(--red);
}

#footer-phone {
  font-size: 1.5em;
  letter-spacing: 1px;
}

@media screen and (max-width: 563px) {
  .logo {
    margin: 0 auto;
  }

  .navbar-brand {
    width: 100%;
    text-align: center;
  }
}